import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/userView/slice';
import { actions as userListActions } from 'redux/users/slice';
import { actions as storeActions } from 'redux/store/slice';
import useUserview from 'redux/userView';
import useMarketplaces from 'redux/marketplaces';
import usePaquerypoints from 'redux/paquerypoint';
import useStore from 'redux/store';
import { marketplacesItemsSelector } from 'redux/marketplaces/selectors';
import { paquerypointsItemsSelector } from 'redux/paquerypoint/selectors';
import { storeItemsSelector, storeLoadedSelector } from 'redux/store/selectors';

import {
  countriesGlobalsSelector,
  rolesGlobalsSelector,
} from 'redux/globals/selectors';
import { userviewAvatarSelector } from 'redux/userView/selectors';
import rest, { getData } from 'util/Api';
import { notification } from 'antd';
import {
  DEFAULT_COUNTRY,
  DEFAULT_ROLE,
  IMAGE_REGEX,
} from 'constants/defaultValues';
import {
  MarketplaceRoles,
  PaqueryPointRoles,
  StoreRoles,
} from 'constants/roles';
import API from 'constants/api';
import SITELINKS from 'constants/sitelinks';
import * as Yup from 'yup';
import { authProfile } from 'redux/auth/selectors';
import User from '../user';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Es necesario indicar el nombre del paquer'),
  lastName: Yup.string().required(
    'Es necesario indicar el apellido del paquer',
  ),
  phone: Yup.number()
    .typeError('El teléfono tiene que ser un número y es requerido')
    .required('Es necesario indicar el número de teléfono'),
  email: Yup.string()
    .email('El formato de correo no es correcto')
    .required('Es necesario indicar el correo electrónico'),
  userRoleID: Yup.number()
    .typeError('Es necesario indicar el rol')
    .required('Es necesario indicar el rol'),
  marketplaceID: Yup.number().when('userRoleID', {
    is: (userRoleID) =>
      MarketplaceRoles.includes(userRoleID) || StoreRoles.includes(userRoleID),
    then: Yup.number()
      .typeError('Se necesita indicar Marketplace')
      .required('Se necesita indicar Marketplace'),
    otherwise: Yup.number().nullable(),
  }),
  storeID: Yup.number().when('userRoleID', {
    is: (userRoleID) => StoreRoles.includes(userRoleID),
    then: Yup.number()
      .typeError('Se necesita indicar Tienda')
      .required('Se necesita indicar Tienda'),
    otherwise: Yup.number().nullable(),
  }),
  paqueryPointID: Yup.number().when('userRoleID', {
    is: (userRoleID) => PaqueryPointRoles.includes(userRoleID),
    then: Yup.number()
      .typeError('Se necesita indicar Paquery Point')
      .required('Se necesita indicar Paquery Point'),
    otherwise: Yup.number().nullable(),
  }),
});

const defaultValues = {
  name: '',
  lastName: '',
  phone: null,
  email: '',
  countryID: DEFAULT_COUNTRY,
  userRoleID: DEFAULT_ROLE,
  paqueryPointID: null,
  marketplaceID: null,
  storeID: null,
};

const disabledInputs = {
  store: true,
};

const UserAdd = () => {
  useUserview();
  useMarketplaces({ initialize: true });
  usePaquerypoints({ initialize: true });
  useStore();
  const dispatch = useDispatch();
  const countries = useSelector(countriesGlobalsSelector);
  const marketplaces = useSelector(marketplacesItemsSelector);
  const paqueryPoints = useSelector(paquerypointsItemsSelector);
  const stores = useSelector(storeItemsSelector);
  const roles = useSelector(rolesGlobalsSelector);
  const avatar = useSelector(userviewAvatarSelector);
  const storeLoaded = useSelector(storeLoadedSelector);
  const [currentRoleId, setCurrentRoleId] = useState(null);
  const [currentCity, setCurrentCity] = useState(null);
  const [currentMarketplace, setCurrentMarketplace] = useState(null);
  const [cities, setCities] = useState([]);
  const history = useHistory();
  const { logisticOperatorID } = useSelector(authProfile);

  useEffect(() => {
    if (StoreRoles.includes(currentRoleId)) {
      if (currentMarketplace && stores.length === 0 && storeLoaded) {
        notification.info({
          message:
            'El marketplace no tiene tiendas. Carga al menos una para asignarla a este rol',
          description:
            'Te redirigiremos hacia el alta para que puedas continuar',
        });
        setTimeout(() => {
          history.push(`/marketplaces/${currentMarketplace}/stores/add`);
        }, 2000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores, currentRoleId]);

  const selectUserRoleId = (roleId) => {
    setCurrentRoleId(roleId);
    if (MarketplaceRoles.includes(roleId) || StoreRoles.includes(roleId)) {
      if (marketplaces.length === 0) {
        notification.info({
          message:
            'Es necesario que cargues al menos un marketplace para asignarlo a este rol',
          description:
            'Te redirigiremos hacia el alta para que puedas continuar',
        });
        setTimeout(() => {
          history.push(SITELINKS.marketplace.add);
        }, 2000);
      }
    }
  };

  const selectCountry = useCallback(
    async (country) => {
      dispatch(actions.loading());
      const citiesResponse = await getData(`${API.globals.cities}/${country}`);
      setCities(citiesResponse);
      if (citiesResponse.length > 0) {
        setCurrentCity(citiesResponse[0].id);
      } else {
        setCurrentCity(null);
      }
      dispatch(actions.loaded());
    },
    [dispatch],
  );

  const selectMarketplace = (marketplaceId) => {
    setCurrentMarketplace(marketplaceId);
    dispatch(storeActions.all(marketplaceId));
    if (marketplaceId) {
      disabledInputs.store = false;
    }
  };

  useEffect(() => {
    selectCountry(DEFAULT_COUNTRY);
  }, [selectCountry]);
  const handleSubmit = async (values) => {
    dispatch(actions.loading());
    const payload = {
      AuthMode: 1,
      Name: values.name,
      LastName: values.lastName,
      Email: values.email,
      UserRoleID: values.userRoleID,
      CountryID: values.countryID,
      CityID: values.cityID,
      Mobile: values.phone,
      Avatar: avatar.name,
      AvatarImg: avatar.name ? avatar.data.replace(IMAGE_REGEX, '') : undefined,
      LogisticOperatorID: logisticOperatorID,
      marketplaceID: values.marketplaceID,
      storeID: values.storeID,
      TermsAndConditions: false,
      paqueryPointID: values.paqueryPointID,
    };
    try {
      const response = await rest.apiAxios.post(API.user.create, payload);
      if (rest.isSuccessResponse(response)) {
        dispatch(userListActions.refreshPage());
        notification.success({
          message: 'Creación de usuario',
          description: `El usuario ${values.name} ${values.lastName} ha sido creado correctamente.`,
        });
        setTimeout(() => {
          history.push('/users');
        }, 1000);
      } else if (
        response.status === 409 
      ) {
        notification.error({
          message: 'Error al intentar crear usuario',
          description: response.data,//`El usuario con correo ${values.email} ya existe`,
        });
      } else {
        notification.error({
          message: 'Ocurrio un problema',
          description: `Ha ocurrido un error al crear un usuario${
            response.data.message ? `: ${response.data.message}` : '.'
          }`,
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      // Nothing to do with error
    } finally {
      dispatch(actions.loaded());
    }
  };
  return (
    <User
      disabledInputs={disabledInputs}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={defaultValues}
      marketplaces={marketplaces}
      paqueryPoints={paqueryPoints}
      stores={stores}
      countries={countries}
      cities={cities}
      roles={roles}
      currentMarketplace={currentMarketplace}
      currentCity={currentCity}
      selectMarketplace={selectMarketplace}
      selectUserRoleId={selectUserRoleId}
      setCurrentCountry={selectCountry}
    />
  );
};

export default UserAdd;
