import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Flex, Popconfirm, Select, Tag, Tooltip } from 'antd';
import {
  ArrowRightOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import { selectGlobals } from 'redux/globals/selectors';
import {
  LastMileRate,
  Rate,
  useCreateLastMileRate,
  useDeleteLastMile,
  useLastMileRates,
  useModifyLastMileRate,
} from 'services/RateService';
import { useZones } from 'services/ZoneService';
import { Marketplace, useMarketplaces } from 'services/MarketplaceService';
import SizeIndicator from 'components/sizeIndicator';
import PaqueryTable from '@paquery-team/lib-table';
import { DEFAULT_PAGINATE } from 'constants/defaultValues';
import { TableDateTimeFormat } from 'constants/dateFormats';
import RateModalButton from './rateModal';

const LastMileRatesList = () => {
  const globals = useSelector(selectGlobals);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [marketplace, setMarketplace] = useState();
  const [zone, setZone] = useState();
  const [packageSize, setPackageSize] = useState();
  const [deliveryTerm, setDeliveryTerm] = useState();
  const {
    allZonesOptions,
    groupingZones: { isFetched: isZonesFetched },
  } = useZones();
  const { data: marketplaces, isFetched: isMkpFetched } = useMarketplaces();
  const queries = useMemo(
    () => ({
      page,
      size,
      marketplace_id: marketplace,
      zone_id: zone,
      package_size: packageSize,
      delivery_term: deliveryTerm,
    }),
    [page, size, marketplace, zone, packageSize, deliveryTerm],
  );
  const {
    data: lastMileResponse,
    isLoading,
    isFetching,
    refetch,
  } = useLastMileRates(queries);
  const { mutate: createLastMile } = useCreateLastMileRate(queries);
  const { mutate: modifyLastMile } = useModifyLastMileRate(queries);
  const { mutate: deleteLastMile } = useDeleteLastMile(queries);

  const marketplaceOptions = useMemo(() => {
    return marketplaces?.content
      ? marketplaces.content.map((mkp: Marketplace) => ({
          label: mkp.name,
          value: mkp.id,
        }))
      : [];
  }, [marketplaces]);

  const pageable = useMemo(() => {
    if (lastMileResponse)
      return {
        pageNumber: lastMileResponse.data.page_number,
        pageSize: lastMileResponse.data.page_size,
        totalElements: lastMileResponse.data.total_elements,
      };
    return DEFAULT_PAGINATE;
  }, [lastMileResponse]);

  const handleOnPaginate = (pagination: {
    pageNumber: number;
    pageSize: number;
  }) => {
    setPage(pagination.pageNumber);
    setSize(pagination.pageSize);
  };

  const idColumn = {
    title: 'ID',
    dataIndex: 'key',
  };

  const mkpColumn = {
    title: 'Marketplace',
    render: (record: LastMileRate) => {
      if (record?.marketplace_name) {
        return record.marketplace_name;
      }

      if (record?.marketplace_id) {
        return <LoadingOutlined />;
      }

      return <MinusOutlined />;
    },
  };

  const zoneColumn = {
    title: 'Zona',
    render: (record: LastMileRate) => {
      if (record?.zone_name) {
        return record.zone_name;
      }

      if (record?.zone_id) {
        return <LoadingOutlined />;
      }

      return <MinusOutlined />;
    },
  };

  const sizeColumn = {
    title: 'Tamaño',
    dataIndex: 'package_size',
    align: 'center',
    render: (text: string) => <SizeIndicator text={text} />,
  };

  const typeColumn = {
    title: 'Plazo de entrega',
    dataIndex: 'delivery_term',
    render: (term: string) => term ?? <MinusOutlined />,
  };

  const rateColumn = {
    title: 'Tarifa',
    render: (rate: LastMileRate) =>
      rate.rate ? (
        <p>
          {rate.currency} <b>{rate.rate}</b>
        </p>
      ) : (
        <MinusOutlined />
      ),
  };

  const costColumn = {
    title: (
      <Flex justify="space-between">
        Costo
        <Tooltip title="Refiere al monto a pagar a los drivers.">
          <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
        </Tooltip>
      </Flex>
    ),
    render: (rate: LastMileRate) =>
      rate.cost ? (
        <p>
          {rate.currency} <b>{rate.cost}</b>
        </p>
      ) : (
        <MinusOutlined />
      ),
  };

  const editColumn = {
    title: 'Editar',
    align: 'center',
    render: (record: LastMileRate) =>
      record.updated ? (
        <LoadingOutlined />
      ) : (
        <RateModalButton
          type="lastMile"
          onSubmit={modifyLastMile}
          title="Edición de tarifa"
          rateId={record.id}
        />
      ),
  };

  const validityColumn = {
    title: 'Periodo de validez',
    align: 'center',
    render: (rate: Rate) => {
      const inInterval = rate.valid_until
        ? dayjs().isAfter(dayjs(rate.valid_since).tz()) &&
          dayjs().isBefore(dayjs(rate.valid_until).tz())
        : dayjs().isAfter(dayjs(rate.valid_since).tz());
      return (
        <p>
          <Tag color={inInterval ? 'green' : 'lightgrey'}>
            {dayjs(rate.valid_since).tz().format(TableDateTimeFormat)}
          </Tag>
          <ArrowRightOutlined />
          <Tag
            color={inInterval ? 'green' : 'lightgrey'}
            style={{ marginLeft: '8px' }}
          >
            {rate.valid_until ? (
              dayjs(rate.valid_until).tz().format(TableDateTimeFormat)
            ) : (
              <MinusOutlined />
            )}
          </Tag>
        </p>
      );
    },
  };

  const deleteColumn = {
    title: 'Eliminar',
    align: 'center',
    render: (record: LastMileRate) =>
      record.updated ? (
        <LoadingOutlined />
      ) : (
        <Popconfirm
          title="Eliminación de tarifa"
          description="Esta tarea no puede deshacerse"
          onConfirm={() => deleteLastMile(record)}
          okText="Confirmar"
          cancelText="Cancelar"
          placement="topRight"
          arrow={{ pointAtCenter: true }}
        >
          <DeleteOutlined style={{ color: 'red', fontSize: 18 }} />
        </Popconfirm>
      ),
  };

  const dataColumns = [
    idColumn,
    mkpColumn,
    zoneColumn,
    sizeColumn,
    typeColumn,
    validityColumn,
    rateColumn,
    costColumn,
  ];

  const fullSizeColumns = [...dataColumns, editColumn, deleteColumn];
  const columnsSmallDevice = [idColumn, rateColumn];

  let mappedRates;
  if (
    lastMileResponse &&
    lastMileResponse.data &&
    lastMileResponse.data.items &&
    lastMileResponse.data.total_elements &&
    lastMileResponse.data.total_elements > 0
  ) {
    mappedRates = lastMileResponse.data.items.map((rate: Rate) => ({
      ...rate,
      key: rate.id,
      delivery_term:
        rate.delivery_term &&
        globals.deliveryTerm.find(
          (term: any) => term.value === rate.delivery_term,
        )?.name,
      package_size:
        rate.package_size &&
        globals.packages.size.find(
          (size: any) => size.value === rate.package_size,
        )?.name,
    }));
  }

  return (
    <PaqueryTable
      loading={isLoading}
      fetching={isFetching}
      header={{
        title: 'Tarifas última milla',
        refresh: refetch,
        selectors: [
          {
            onChange: (value) => setDeliveryTerm(value),
            list: globals.deliveryTerm,
            placeholder: 'Plazo',
          },
          {
            onChange: (value) => setPackageSize(value),
            list: globals.packages.size,
            placeholder: 'Tamaño de paquete',
          },
        ],
        extraMainAction: (
          <>
            <Select
              options={marketplaceOptions}
              disabled={!isMkpFetched}
              loading={!isMkpFetched}
              style={{ minWidth: '180px', marginRight: '8px' }}
              placeholder="Marketplace"
              onChange={(value) => setMarketplace(value)}
              onClear={() => setMarketplace(undefined)}
              allowClear
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
            <Select
              options={allZonesOptions}
              disabled={!isZonesFetched}
              loading={!isZonesFetched}
              style={{ minWidth: '180px' }}
              placeholder="Zona"
              onChange={(value) => setZone(value)}
              onClear={() => setZone(undefined)}
              allowClear
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </>
        ),
        primaryButton: (
          <RateModalButton
            type="lastMile"
            title="Creación de Tarifa"
            onSubmit={createLastMile}
            close={isFetching}
          />
        ),
      }}
      onChangePaginate={handleOnPaginate}
      dataSource={mappedRates}
      paginate={pageable}
      dataColumns={dataColumns}
      colsForMediumDevice={fullSizeColumns}
      colsForSmallDevice={columnsSmallDevice}
      colsForLargeDevice={fullSizeColumns}
    />
  );
};

export default LastMileRatesList;
